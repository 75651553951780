<template>
  <div v-if="ready">
    <!-- <ProgressBar :value="bar"/> -->
    <!-- <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-cbd5e1e0" class="div-precedent" v-if="souscription.last_step > 1">
    <a @click="previous()" class="zone-precedent w-inline-block"></a>
  </div> -->
  <transition name="fade">
    <component :is="getStep()" :souscription="souscription" :user="user" :proprietaire="proprietaire" :params="params"></component>
  </transition>
</div>
</template>

<script>
//import ProgressBar from '@/components/ProgressBar'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Wait from './Wait';
export default {
  name: "ZeroDepot",
  components: {
    Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9, Wait
  },
  data(){
    return {
      ready:false,
      bar:5,
      params:false,
      user:{
        id:null,
        email:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        telephone:null,
        password:null
      },
      souscription:{
        id:null,
        user_id:null,
        provisoir:null,
        loyer:null,
        meublee:false,
        logement:{
          adresse:null,
          adresse2:null,
          cp:null,
          ville:null,
          pays:"France",
          date_bail:null
        },
        proprietaire_id:null,
        coup_dur:null,
        last_step:1,
        paiement:{
          periode:null,
          type_id:null,
          stripe_cb_id:null
        },
        api_key:null,
        api_type:null,
        apt_ref:null,
        agence:false,
        v:2,
        pay_v:2
      },
      proprietaire:{
        nom:null,
        email:null,
        telephone:null
      },
      flag:false
    }
  },
  watch:{
    'user.telephone'(){
      this.user.telephone = this.user.telephone.replaceAll(" ","")
    }
  },
  methods: {
    getStep() {
      var wait = true;
      if(wait){
        return 'Wait'; 
      }
      var name = "Step";      
      name = name+this.souscription.last_step;
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.flag = false;
      },500)
      return name;
    },
    next(){
      if(this.flag === true){
        return false;
      }
      this.flag = true;
      this.souscription.last_step = this.souscription.last_step + 1;
    },
    previous(val = false){
      if(val === false){
        val = this.souscription.last_step - 1;
      }
      this.souscription.last_step = val;      
    },    
  },
  beforeMount(){
    if(this.$route.query.uuid != undefined){
      this.$store.api.ajax('/zero-depot/get/'+this.$route.query.uuid, null, (res) => {
        if(res.status === true){
          this.user = res.data.user;
          this.souscription = res.data.souscription;
          if(res.data.proprietaire != false){
            this.proprietaire = res.data.proprietaire;
          }
          this.next();
          this.$root.$emit('userData', this.user);
        }
      })
    }
    this.$store.api.ajax('/zero-depot/params', null, (res) => {
      if(res.status === true){
        this.params = res.data
        this.ready = true;
      }
    });
    if(this.$route.query.key != undefined){
      this.souscription.api_key = this.$route.query.key;
      this.souscription.api_type = parseInt(this.$route.query.type);
    }
    if(this.$route.query.loyer != undefined){
      this.souscription.loyer = parseFloat(this.$route.query.loyer);
    }
    if(this.$route.query.meuble != undefined){
      this.souscription.meublee = this.$route.query.meuble === 'non' ? false : true;
    }
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-form";
  },
  beforeDestroy() {
    document.getElementsByTagName('body')[0].className = "body-crm"
  }
};
</script>
<style>
.btn-no-valide {
  background: #E0E0E0 !important;
}
/*! CSS Used from: https://pautions.fr/css/normalize.css */
/*! CSS Used from: https://pautions.fr/css/components.css */

.form_calculateur {
  padding-top: 7px;
  padding-bottom: 0px;
  padding-left: 20px;
  border-radius: 15px;
  font-family: Hind, sans-serif;
  text-align: left;
}
.form_calculateur.v2 {
  margin-bottom: 0px;
  padding-top: 6px;
  background-image: url("https://pautions.fr/images/ic_euro.svg");
  background-position: 92% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}
@media screen and (max-width: 479px) {
  .form_calculateur {
    font-size: 30px;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
